import { cn } from "@/lib/utils";

export interface IContentLayout extends React.ComponentPropsWithoutRef<"section"> {
  bgColorClass?: string;
  className?: string;
  innerClass?: string;
  paddingBottom?: string;
}

const ContentLayout: React.FC<IContentLayout> = ({
  bgColorClass = "",
  children,
  className,
  innerClass,
  paddingBottom,
  ...divProps
}) => {
  return (
    <div
      className={cn(
        "w-full flex flex-col justify-center",
        {
          [bgColorClass]: bgColorClass,
        },
        className
      )}
      {...divProps}
    >
      <div
        className={cn(
          `w-full mx-auto xl:max-w-screen-2xl lg:max-w-screen-xl sm:max-w-screen-md md:max-w-screen-lg
            sm:py-20 py-12 px-4 lg:px-8 xl:px-20 2xl:px-[104px]`,
          innerClass,
          paddingBottom
        )}
      >
        {children}
      </div>
    </div>
  );
};

export default ContentLayout;
