"use client";

import AppText from "@/components/AppText";
import ContentLayout from "@/components/layouts/ContentLayout";

export default function Error({ error, reset }: { error: Error & { digest?: string }; reset: () => void }) {
  return (
    <>
      <ContentLayout>
        <AppText type="TITLE_MEDIUM">Something went wrong </AppText>
        <AppText type="BODY_SMALL">general error</AppText>
        <button id="button-try-again" onClick={() => reset()}>
          Try again
        </button>
        <pre>{JSON.stringify(error, null, 2)}</pre>
      </ContentLayout>
    </>
  );
}
