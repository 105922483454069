import React from "react";
import { cn } from "@/lib/utils";
import {
  BODY_LARGE,
  BODY_LARGE_EMPHASIS,
  BODY_MEDIUM,
  BODY_MEDIUM_EMPHASIS,
  BODY_SMALL,
  BODY_SMALL_EMPHASIS,
  BUTTON_MEDIUM,
  BUTTON_SMALL,
  CAPTION_MEDIUM,
  HEADLINE_LARGE,
  HEADLINE_MEDIUM,
  HEADLINE_SMALL,
  LABEL_EMPHASIS,
  LABEL_LARGE,
  SUB_HEADING_LARGE,
  SUB_HEADING_MEDIUM,
  SUB_HEADING_SMALL,
  TITLE_LARGE,
  TITLE_MEDIUM,
  TITLE_SMALL,
  TITLE_X_SMALL,
} from "../styles";

type TextType =
  | "HEADLINE_LARGE"
  | "HEADLINE_MEDIUM"
  | "HEADLINE_SMALL"
  | "TITLE_LARGE"
  | "TITLE_MEDIUM"
  | "TITLE_SMALL"
  | "TITLE_X_SMALL"
  | "SUB_HEADING_LARGE"
  | "SUB_HEADING_MEDIUM"
  | "SUB_HEADING_SMALL"
  | "BODY_LARGE_EMPHASIS"
  | "BODY_LARGE"
  | "BODY_MEDIUM_EMPHASIS"
  | "BODY_MEDIUM"
  | "BODY_SMALL_EMPHASIS"
  | "BODY_SMALL"
  | "BUTTON_MEDIUM"
  | "BUTTON_SMALL"
  | "LABEL_LARGE"
  | "LABEL_EMPHASIS"
  | "CAPTION_MEDIUM";
interface AppTextProps {
  type: TextType;
  className?: string;
  title?: string;
  children: React.ReactNode;
  as?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p";
}

const AppText: React.FC<AppTextProps> = ({ type, className, children, title, as }) => {
  let textStyles = HEADLINE_LARGE;
  let TextTag: keyof React.JSX.IntrinsicElements = "p";

  switch (type) {
    case "HEADLINE_LARGE":
      textStyles = HEADLINE_LARGE;
      TextTag = as ?? "h1";
      break;
    case "HEADLINE_MEDIUM":
      textStyles = HEADLINE_MEDIUM;
      TextTag = as ?? "h2";
      break;
    case "HEADLINE_SMALL":
      textStyles = HEADLINE_SMALL;
      TextTag = as ?? "h3";
      break;
    case "TITLE_LARGE":
      textStyles = TITLE_LARGE;
      TextTag = as ?? "h2";
      break;
    case "TITLE_MEDIUM":
      textStyles = TITLE_MEDIUM;
      TextTag = as ?? "h2";
      break;
    case "TITLE_SMALL":
      textStyles = TITLE_SMALL;
      TextTag = as ?? "h2";
      break;
    case "TITLE_X_SMALL":
      textStyles = TITLE_X_SMALL;
      TextTag = as ?? "h2";
      break;
    case "SUB_HEADING_LARGE":
      textStyles = SUB_HEADING_LARGE;
      TextTag = as ?? "h2";
      break;
    case "SUB_HEADING_MEDIUM":
      textStyles = SUB_HEADING_MEDIUM;
      TextTag = as ?? "h2";
      break;
    case "SUB_HEADING_SMALL":
      textStyles = SUB_HEADING_SMALL;
      TextTag = as ?? "h3";
      break;
    case "BODY_LARGE_EMPHASIS":
      textStyles = BODY_LARGE_EMPHASIS;
      TextTag = as ?? "p";
      break;
    case "BODY_LARGE":
      textStyles = BODY_LARGE;
      TextTag = as ?? "p";
      break;
    case "BODY_MEDIUM_EMPHASIS":
      textStyles = BODY_MEDIUM_EMPHASIS;
      TextTag = as ?? "p";
      break;
    case "BODY_MEDIUM":
      textStyles = BODY_MEDIUM;
      TextTag = as ?? "p";
      break;
    case "BODY_SMALL_EMPHASIS":
      textStyles = BODY_SMALL_EMPHASIS;
      TextTag = as ?? "p";
      break;
    case "BODY_SMALL":
      textStyles = BODY_SMALL;
      TextTag = as ?? "p";
      break;
    case "BUTTON_MEDIUM":
      textStyles = BUTTON_MEDIUM;
      TextTag = as ?? "p";
      break;
    case "BUTTON_SMALL":
      textStyles = BUTTON_SMALL;
      TextTag = as ?? "span";
      break;
    case "LABEL_LARGE":
      textStyles = LABEL_LARGE;
      TextTag = as ?? "p";
      break;
    case "LABEL_EMPHASIS":
      textStyles = LABEL_EMPHASIS;
      TextTag = as ?? "p";
      break;
    case "CAPTION_MEDIUM":
      textStyles = CAPTION_MEDIUM;
      TextTag = as ?? "p";
      break;
  }

  return (
    <TextTag className={cn(textStyles, className)} title={title}>
      {children}
    </TextTag>
  );
};

export default React.memo(AppText);
